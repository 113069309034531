
<template>
  <v-card elevation="0">
    <v-card-title>
      {{$t('workspace.contentTypes', locale)}}
      <v-spacer />
      <v-btn
        color="primary"
        fab
        elevation="0"
        small
        @click="handleAddItem"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="padding:0px;">
      <draggable-table-content-type
        :data="data"
        :onCancelEdit="handleCancelEdit"
        :onSortItems="handleSortItems"
        :onSortStart="handleSortStart"
        :onGetData="handleGetData"
        :onEdit="handleEdit"
        :onDelete="handleDelete"
      />
      <content-type-form 
        :showForm="showForm"
        :data="editedItem"
        :onClose="handleCancelEdit"
        :onDelete="handleDelete"
        :onChangeContentType="handleChangeContentType"
      />
    </v-card-text>
  </v-card>
</template>
<script>
  const defaultItem = {
    ID: null,
    Name: null,
    Type: null,
    Width: 1280,
    Height: 720,
    AllowedTypes: null,
    ORD: null
  }
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import DraggableTableContentType from './DraggableTableContentType'
  import ContentTypeForm from './ContentTypeForm'
  export default{
    name: 'ContentType',
    components: {
      DraggableTableContentType,
      ContentTypeForm,
    },
    props: {
      workspaceID: {
        type: String,
        required: true
      },
      onChangeContentTypes: {
        type: Function,
        required: true
      },
    },
    data () {
      return {
        tmpNewItemID: 0,
        showForm: false,
        editedItem: null,
        data: [
        ],
      }
    },
    computed: {
      ...mapState('app', ['locale']),
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData () {
        api.getAllWithoutLimit ('dtouch', `v1/private/workspaces/${this.workspaceID}/content-types`)
          .then(response => {
            console.log('jhm', response)
            this.data = response
            this.onChangeContentTypes(this.data)
          })
      },
      handleCancelEdit () {
        this.showForm = false
      },
      handleSortItems (v) {
        this.data = v
        if (this.data && this.data.length)
          for (let i = 0; i < this.data.length; i++) {
            this.data[i].ORD = i
          }
      },
      handleSortStart () {
      },
      activeDragable () {
      },
      handleEdit (v) {
        this.editedItem = v
        this.showForm = true
      },
      handleDelete (v) {
        this.data = this.data.filter(x => `${x.ID}${x.tmpNewItemID}` !== v)
        this.onChangeContentTypes(this.data)
      },
      handleChangeContentType (v) {
        if(this.data.filter(x => `${x.ID}${x.tmpNewItemID}` === `${v.ID}${v.tmpNewItemID}`).length > 0) {
          this.data = this.data.map(item => {
            if (`${item.ID}${item.tmpNewItemID}` === `${v.ID}${v.tmpNewItemID}`) return v
            else return item
          })
        } else {
          const max = this.data && this.data.length > 0 ? this.data.reduce((prev, current) => (prev.ORD > current.ORD) ? prev : current).ORD : null
          v.ORD = max && max >= 0 ? max + 1 : 1
          this.data.push(v)
        }
        this.onChangeContentTypes(this.data)
        this.showForm = false
      },
      handleAddItem () {
        this.editedItem = JSON.parse(JSON.stringify(defaultItem))
        this.editedItem.tmpNewItemID = this.tmpNewItemID.toString()
        this.tmpNewItemID ++

        this.showForm = true
      }
    }
  }
</script>
